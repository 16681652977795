import React from "react"
import SEO from "../components/seo"
import { ReactTypeformEmbed } from "react-typeform-embed"

// test

const ContactPage = () => {
  return (
    <>
      <SEO
        title="Contact | Zector"
        image="https://zectorimages.s3.amazonaws.com/zector-banner.jpg"
        url="https://www.zector.io/contact"
        description="Contact Info. 9466 Georgia Ave #1147 Silver Spring MD 20910 · info@zector.io · 240-712-4053"
      />
      <ReactTypeformEmbed url="https://form.typeform.com/to/ev0ejhYC" />
    </>
  )
}

export default ContactPage
